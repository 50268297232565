export enum CustomColor {
  primaryL = 'rgb(205, 234, 231)',
  primary = 'rgb(51, 122, 183)',
  primaryD = 'rgb(46, 109, 164)',
  primaryDD = 'rgb(38, 93, 154)',
  blackT = 'rgba(0,0,0,.3);',
  blackL = 'rgb(85, 85, 85)',
  black = 'rgb(51, 51, 51)',
  white = 'rgba(255, 255, 255, 1)',
  whiteT = 'rgba(255, 255, 255, 0.952941)',
  greyLLLL = 'rgb(240, 240, 240)',
  greyLLL = 'rgb(200, 200, 200)',
  greyLL = '#888',
  greyL = '#777',
  grey = '#7d7d7d',
  transparent = 'transparent',
}
